import { Link, usePage } from '@inertiajs/react';
import { Box, Button, Container, Flex, Grid, Group, Image, Progress, rem, Stack, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

export default function SalesProgress() {
  const { salesProgress: offer } = usePage().props;

  if (!offer) {
    return <></>;
  }

  // is mobile var mantine way
  const isMobile = useMediaQuery('(max-width: 768px)');
  const c = r => route().current(r);

  if (
    !(
      c('site.index') ||
      c('site.how-it-works') ||
      c('site.faq') ||
      c('site.contact') ||
      c('site.legal.*') ||
      c('site.about') ||
      c('site.account.*') ||
      c('site.earn')
    )
  ) {
    return <></>;
  }

  return (
    <Box bg={'gfdark.9'} c={'white'}>
      <Container>
        <Grid grow justify="space-between" align="center" gutter={0}>
          <Grid.Col span={{ sm: 6 }}>
            <Box py={'xl'}>
              <Group align={'center'} justify={'stretch'}>
                <Link href={offer.url}>
                  <Image h={100} w={140} src={offer?.artwork?.images[0]?.url} fit={'contain'} />
                </Link>
                <Stack gap={0} style={{ flex: 1 }}>
                  <Link href={offer.url}>
                    <Text fz={rem(24)} c={'white'} lh={1}>
                      {offer?.artwork?.name}
                    </Text>
                    <Text fz={rem(14)} fw={'lighter'} c={'gfdark.4'}>
                      {offer?.artwork?.artist?.name}
                    </Text>
                  </Link>

                  <Text mt={'md'} fz={rem(16)} fw={'lighter'} c="white">
                    {offer.progress}% Progress
                  </Text>
                  <Progress mt={5} size={'sm'} color="orange" radius={'xs'} value={offer.progress} />
                </Stack>
              </Group>
            </Box>
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Flex justify={isMobile ? 'center' : 'flex-end'} align="center" pb={isMobile ? 'xl' : 0}>
              <Stack gap={0}>
                <Text fw={'lighter'} fz={rem(18)} c="gfdark.4">
                  {' '}
                  <Text span c={'white'} fz={rem(18)}>
                    {offer.unique_owners}
                  </Text>{' '}
                  Collectors,{' '}
                  <Text span c={'white'} fz={rem(18)}>
                    {offer.unique_countries}
                  </Text>{' '}
                  Countries
                </Text>
                <Button component={Link} href={offer.url} size="lg" mt={'lg'} c={'white'}>
                  BUY NOW
                </Button>
              </Stack>
            </Flex>
          </Grid.Col>
        </Grid>
      </Container>
    </Box>
  );
}
